import React from 'react';
import styled from 'styled-components';
import auraImage from '../assets/aura-app.png';  // You'll need to add these images
import visionTranslateImage from '../assets/vision-translate-app.webp';

const ProductsContainer = styled.div`
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const ProductCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const ProductTitle = styled.h2`
  color: #e56d90;
  margin-bottom: 1rem;
`;

const ProductDescription = styled.p`
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const AppStoreButton = styled.a`
  display: inline-block;
  background-color: #e56d90;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #d15c7d;
  }
`;

const Products: React.FC = () => {
  return (
    <ProductsContainer>
      <h1>Our Products</h1>
      <ProductsGrid>
        <ProductCard>
          <ProductImage src={auraImage} alt="AURA App" />
          <ProductTitle>AURA</ProductTitle>
          <ProductDescription>
          AURA's style is different than that of other AI Dating Assistants.
          This is because AURA imbues you with STYLE, UNWAVERING BOLDNESS, and a TAKE IT OR LEAVE IT attitude—the kind that people flock to.
          We believe the right attitude counts more than all the material possessions in the world.
</ProductDescription>
          <AppStoreButton href="https://apps.apple.com/us/app/aura-ai-dating-assistant/id6480233912" target="_blank">
            Download on App Store
          </AppStoreButton>
        </ProductCard>

        <ProductCard>
          <ProductImage src={visionTranslateImage} alt="VisionTranslate App" />
          <ProductTitle>VisionTranslate</ProductTitle>
          <ProductDescription>
            Unlock your auditory world with VisionTranslate, a groundbreaking VisionOS application designed for those with hearing loss. 
            VisionTranslate captures speech and surrounding audio, converting them into live captions displayed seamlessly into your field of view.
          </ProductDescription>
          <AppStoreButton href="https://apps.apple.com/us/app/visiontranslate-live-captions/id6705126831" target="_blank">
            Download on App Store
          </AppStoreButton>
        </ProductCard>
      </ProductsGrid>
    </ProductsContainer>
  );
};

export default Products;