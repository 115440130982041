import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './components/Navbar';
import Content from './components/MainContent';
import Products from './components/Products';
import FooterComponent from './components/Footer';

const Container = styled.div`
  background-color: #ffffff;
  color: #e56d90;
  font-family: 'Helvetica Neue', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const App: React.FC = () => {
  return (
    <Router>
      <Container>
        <Navbar />
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="/products" element={<Products />} />
        </Routes>
        <FooterComponent />
      </Container>
    </Router>
  );
};

export default App;